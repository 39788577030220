<template>
  <div class="linechart-loaded">
    <content-placeholders :rounded="true" class="content-loader-x">
      <content-placeholders-img />
    </content-placeholders>
  </div>
</template>

<script>


export default {
  name: 'Ticker-loaded',
  components: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

  },
  props: {
  },
}
</script>
<style>
.linechart-loaded .vue-content-placeholders-img {
  height: 260px;
}
</style>
