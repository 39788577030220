<template>
  <div class="doughnut-loaded">
    <div class="md-layout md-gutter parent">
      <div class="md-layout-item md-size-50">
        <content-placeholders :rounded="true" class="content-loader-1">
          <content-placeholders-text :lines="4"/>
        </content-placeholders>
      </div>
      <div class="md-layout-item md-size-50">
        <content-placeholders :rounded="true" class="content-loader-2">
          <content-placeholders-img />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Doughnut-loader',
  components: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  props: {},
}
</script>
<style>
.doughnut-loaded .content-loader-1 {
  margin: 35% 0 0 30px;
}
.doughnut-loaded .content-loader-2 {
  margin: 40px 30px auto auto;
}
.doughnut-loaded .vue-content-placeholders-text__line:nth-child(1) {
  height: 35px !important;
}

.doughnut-loaded .vue-content-placeholders-text__line:nth-child(n+2) {
  height: 20px !important;
}

.doughnut-loaded .vue-content-placeholders-img {
  height: 240px;
}

</style>
