<template>
  <div class="temp-text"  style="width: 100%">
    <h3>Mest populära storyspots under {{getDate}}</h3>
    <div class="md-layout md-gutter">
      <div v-if="!data" v-for="(item,index) in [1,2,3]" class="md-layout-item md-size-33">
        <md-card>
          <TopStoryspotLoader class="storyspot-content-loader"/>
        </md-card>
      </div>
      <div v-for="(item, index) in data" class="md-layout-item md-size-33 md-small-size-100">
        <md-card class="card">
          <h1 :style="{color: getColor(index)}">{{(index+1)}}.</h1>
          <h3 style=" height: 56px;">{{item.name}}</h3>
          <p>{{item.author.fullName}}</p>
          <p>
            <small>
            <badge :style="`background-color: ${item.category.color}; font-size: 14px;`">
              {{ getCategoryName(item) }}
            </badge>
          </small>
          </p>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import StoryspotCell from "../../../../shared/components/cells/StoryspotCellSmall";
import TopStoryspotLoader from "../loaders/TopStoryspotLoader";
import {colors, months} from "../../utils/contants";
import Badge from "../../../../shared/components/Badge";
export default {
  name: 'TopStoryspot',
  components: {
    StoryspotCell,
    TopStoryspotLoader,
    Badge
  },
  data() {
    return {}
  },
  mounted() {
  },
  props: {
    data: {
      default: {}
    }
  },
  methods: {
    getColor(index) {
      return colors[index];
    },
    getCategoryName(item) {
      if (item.category.names && item.category.names.sv) {
        return item.category.names.sv;
      } else {
        return item.category.name;
      }
    }
  },
  computed: {
    getDate() {
      let date = new Date();
      date.setMonth(date.getMonth()-1);
      return months[date.getMonth()];
    }
  }
}
</script>
<style scoped>
.card {
  padding: 30px;
  height: 280px;
}
h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  font-family: 'Inter';
}
h3 {
  font-size: 24px;
  font-weight: normal;
  line-height: 28px;
}
p {
  font-size: 16px;
  line-height: 24px;
}

.storyspot-content-loader {
  width: 350px;
  align-items: center;
  justify-content: center;
  margin: 60px 66px 0 66px;
}

.temp-text .vue-content-placeholders-text__line:nth-child(1) {
  height: 50px !important;
}
.temp-text  .vue-content-placeholders-text__line:nth-child(n+2) {
  height: 27px !important;
}
.temp-text .vue-content-placeholders-text__line:nth-child(n+3) {
  height: 14px !important;
}
.md-card {
  margin: auto auto auto auto;
}

.md-layout.md-gutter > .md-layout-item {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (max-width: 1048px) {
  .md-card {
    margin-top: 15px;
  }
}

</style>
