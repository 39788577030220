export const colors = [
    '#D35000',
   // '#8D3600',
    '#FF8A00',
    '#FFBE66',
    '#FFD9A7',
    '#F9E0C7',
    '#E8D5B7',
    '#C2A377',
    '#A1885F',
    '#795548',
    '#4E342E',
];

export const months = [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
]