<template>
  <div class="fab-container">
    <p class="fab-text">Hjälp oss att bli bättre<br>genom att dela med<br> dig av din feedback</p>
    <!-- <div class="fab">
      <md-button class="md-fab md-mini md-primary">
        <md-icon>add</md-icon>
      </md-button>
    </div> -->
    <div class="fab-button md-ripple" @click="openLink">
      <md-icon>feedback</md-icon>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  mounted() {
  },
  methods: {
    openLink() {
      window.open('https://docs.google.com/forms/d/1_pWQWGcXzjmT2tlOxcrhdD309UzGc2-sS3mIW-GoEVo/edit', "_blank")
    }
  },
  computed: {},
  data() {
    return {}
  },
}
</script>
<style lang="css">
.fab-container {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 99999;
}
.fab-text {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.fab-button {
  float: right;
  width: 75px !important;
  height: 75px !important;
  background-color: #FF8500 !important;
  border-radius: 42px !important;
  transition: all 0.1s ease-in-out;
  font-size: 50px;
  color: white !important;
  text-align: center;
  line-height: 70px;
}
.fab-button i {
  color: white !important;
}

.fab-button:hover {
  box-shadow: 0 0 8px 0 #fff;
  background-color: #FF8500 !important;
  transform: scale(1.05);
}
</style>