<template>
  <div class="wrapper">
    <div class="main">
      <div class="section">
        <div class="container">
            <div class="header">
              <h1>
                Våra användare
              </h1>
              <p>
                Använder med tillgång att se organisationens statistik
              </p>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center">
                <div v-for="user in getUsers">
                  <User :user="user" :showStoryspots="false" :canRedirect="false"></User>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import User from '../../../shared/components/cells/UserCell'
import BreadCrumbs from "../../../shared/components/Breadcrumb";
export default {
  bodyClass: "Org-users",
  components: {
    User,
    BreadCrumbs
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
      ]
    }
  },
  mounted() {
  },
  computed: {
    getUsers() {
      return this.$store.getters.getOrganizationUsers;
    }
  }
}
</script>
<style>
.header {
  margin-bottom: 44px;
}
.main {
  min-height: 80vh;
  z-index: 0;
}
.section {
  padding-top: 20px !important;
}
</style>