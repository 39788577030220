<template>
  <div>
    <DoughnutLoader v-if="!data"/>
    <div v-else class="md-layout md-gutter parent">
      <div class="md-layout-item md-size-50">
        <h2 v-if="getData.key.length > 0" class="list-headline">Orter</h2>
        <h2 v-else class="list-headline">Ingen data tillgänglig</h2>
        <div v-for="(item, index) in getData.key.filter((a) => a !== 'Övrigt').slice(0,3)" class="text-div">
          <div class="pill" :style="{backgroundColor: data.datasets[0].backgroundColor[index]}"/>
          <h4>{{ item }} - <p class="number-font">{{ getData.value[getData.key.indexOf(item)] }}%</p></h4>
        </div>
      </div>
      <div class="md-layout-item md-size-50">
        <div class="doughnut-div">
          <Doughnut :chart-data="data" :chart-options="options" :style="styles"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Doughnut} from 'vue-chartjs/legacy'
import DoughnutLoader from '../loaders/DoughnutChart-loader'

import {
  Chart as ChartJS,
  Tooltip,
  ArcElement,
} from 'chart.js'

ChartJS.register(ArcElement, Tooltip)
export default {
  name: 'DoughnutChart',
  components: {
    Doughnut,
    DoughnutLoader
  },

  data() {
    return {
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                return ' ' + context.label + ': ' + context.raw + '%';
              },
            }
          },
        },
        legend: {
          visible: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            loop: false
          }
        },
      },
    }
  },
  computed: {
    styles() {
      return {
        "height": '240px',
        "max-height": "260px !important"
      }
    },
    getData() {
      return {
        key: this.data.labels,
        value: this.data.datasets[0].data
      }
    }
  },
  props: {
    data: {},
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
  },
}
</script>
<style scoped>
.parent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-div {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 30px;
  margin-right: 0;
}

.doughnut-div {
  margin-left: 0;
  margin-right: 30px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.pill {
  border: none;
  color: black;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 8px;
  cursor: pointer;
  border-radius: 8px;
  height: 10px;
}

h4 {
  font-size: 16px;
  margin: 0px;
}

.list-headline {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 40px;
  margin-right: 0;
  margin-bottom: 9px;
  font-size: 24px;
  line-height: 27px;
  font-weight: 700;
}

.number-font {
  margin: 0;
  padding: 0;
  font-size: 16px;
  display: inline-block;
  font-weight: normal !important;
  font-family: 'Inter';
}
</style>
