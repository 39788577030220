import baseApi from '../../../shared/services/base-api-service'
import sharedConfig from "../../../shared/shared-config";
import Vue from "vue";
import {groupBy} from "../../../shared/utils/general-utils";
import {Storyspot} from "../../../shared/models/Storyspot.model";

const state = {
    hasLoaded: false,
    isLoading: false,
    total: null,
    byCity: null,
    byDay: null,
    data: null,
    storyspotStats: {},
    hasLoadedPopular: false,
    mostPopular: null,
}
const getters = {
    getDashboardData(state) {
        return {
            total: state.total,
            byCity: state.byCity,
            byDay: state.byDay,
        }
    },
    getMostPopular(state) {
        return state.mostPopular;
    },
    getStoryspotStats(state) {
        return state.storyspotStats;
    },
    hasLoadedPopular(state) {
        return state.hasLoadedPopular;
    },
    hasLoadedStats(state) {
        return state.hasLoaded;
    },
    isLoadingStats(state) {
        return state.isLoading;
    }
}

const actions = {
    // Used for logging out etc.
    [sharedConfig.store.analytics.CLEAR_DATA]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.store.analytics.CLEAR_DATA_SUCCESS);
    },
    [sharedConfig.store.analytics.GET_STORYSPOT_STATS]: (
        { commit, dispatch, state },
        storyspotId
    ) => {
        return new Promise((resolve, reject) => {
            const awaitValue = function () {
                if (!state.hasLoaded) {
                    if (!state.isLoading) {
                        dispatch(sharedConfig.store.analytics.GET_STATS);
                    }
                    setTimeout(() => awaitValue(), 1000);
                }  else {
                    if (state.data) {
                        let data = state.data;
                        let groupCity = groupBy(data, 'city')
                        let byCity = {};
                        let total = 0;
                        for (let i=0; i<Object.keys(groupCity).length; i++) {
                            let key = Object.keys(groupCity)[i];
                            let values = groupCity[key].filter((a) => a.storyspotId === storyspotId);
                            if (values.length > 0) {
                                byCity[key] = values;
                                total += values.length;
                            }
                        }

                        let groupDay = groupBy(
                            data.map((a) => {
                                let date = new Date(a.event_timestamp / 1000);
                                a.date = date;
                                let day = new Date(a.event_timestamp / 1000)
                                day.setHours(0, 0, 0, 0);
                                a.day = day;
                                return a;
                            }), 'day');
                        let byDay = {};
                        for (let i=0; i<Object.keys(groupDay).length; i++) {
                            let key = Object.keys(groupDay)[i];
                            let values = groupDay[key].filter((a) => a.storyspotId === storyspotId);
                            if (values.length > 0) {
                                byDay[key] = values;
                            }
                        }
                        resolve({
                            total: total,
                            byDay: byDay,
                            byCity: byCity
                        });
                    }
                }
            }
            awaitValue();
        });
    },
    [sharedConfig.store.analytics.GET_STATS]: (
        { commit, dispatch }
    ) => {
        commit(sharedConfig.store.analytics.GET_STATS);
        return new Promise((resolve, reject) =>  {
            baseApi.getData( sharedConfig.api.organization.statsEndpoint)
                .then((statsData) => {
                    commit(sharedConfig.store.analytics.GET_STATS_SUCCESS, statsData);
                    resolve();
                }).catch((e) => {
                commit(sharedConfig.store.analytics.GET_STATS_ERROR, e);
                console.log(e);
                reject(e);
            });
        })

    },
    [sharedConfig.store.analytics.GET_MOST_POPULAR]: (
        { commit, dispatch }
    ) => {
        return new Promise((resolve, reject) =>  {
            baseApi.getData(sharedConfig.api.organization.mostPouplarEndpoint)
                .then((statsData) => {
                    commit(sharedConfig.store.analytics.GET_MOST_POPULAR_SUCCESS, statsData);
                    resolve();
                }).catch((e) => {
                commit(sharedConfig.store.analytics.GET_MOST_POPULAR_ERROR, e);
                console.log(e);
                reject(e);
            });
        })

    },
}

const mutations = {
    [sharedConfig.store.analytics.CLEAR_DATA_SUCCESS]: (state) => {
        state.stats = null;
        state.hasLoaded = false;
        state.isLoading = false;
    },
    [sharedConfig.store.analytics.GET_STATS]: (state) => {
        state.isLoading = true;
    },
    [sharedConfig.store.analytics.GET_STATS_SUCCESS]: (state, resp) => {
        state.total = resp.data.total;
        state.data = resp.data.data;
        const reqursive = function (state, data) {
            state.byCity = groupBy(data, 'city');
            let byDay = groupBy(
                data.map((a) => {
                    let date = new Date(a.event_timestamp/1000);
                    a.date = date;
                    let day = new Date(a.event_timestamp/1000)
                    day.setHours(0,0,0,0);
                    a.day = day;
                    return a;
                }), 'day');
            state.byDay = Object.keys(byDay).sort((a,b) => new Date(b)-new Date(a)).reduce(
                (obj, key) => {
                    obj[key] = byDay[key];
                    return obj;
                },
                {}
            );
        }
        reqursive(state, resp.data.data);

        state.isLoading = false;
        state.hasLoaded = true;
    },
    [sharedConfig.store.analytics.GET_STORYSPOT_STATS_ERROR]: (state, e) => {
        const instance = Vue.$toast.open({
            message: "There was an error processing your request.",
            type: "error",
            position: "bottom"
        });
    },
    [sharedConfig.store.analytics.GET_STATS_ERROR]: (state, e) => {
        state.isLoading = false;
        const instance = Vue.$toast.open({
            message: "There was an error processing your request.",
            type: "error",
            position: "bottom"
        });
    },
    [sharedConfig.store.analytics.GET_MOST_POPULAR_SUCCESS]: (state, resp) => {
        state.mostPopular = resp.data.map((a) => new Storyspot(a))
        state.hasLoadedPopular = true;
    },
    [sharedConfig.store.analytics.GET_MOST_POPULAR_ERROR]: (state, e) => {
        const instance = Vue.$toast.open({
            message: "There was an error processing your request.",
            type: "error",
            position: "bottom"
        });
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};