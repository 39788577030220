<template>
  <div class="login-box">
    <div class="md-layout md-gutter md-alignment-center-center">
      <div>
        <md-card class="login-card">
          <div class="md-layout-item md-size-100">
            <img
                class="logo-img"
                src="../assets/logo_480.png"
                alt="Storyspot logo"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <h1 class="text-center">Logga in</h1>
            <md-card-content class="input-container">
              <md-field required :class="{ 'md-invalid': formValidators.email }">
                <md-icon>email</md-icon>
                <label>Email</label>
                <md-input v-model="form.email" type="text"></md-input>
                <span class="md-error">{{ formValidators.email }}</span>
              </md-field>
              <md-field required :class="{ 'md-invalid': formValidators.password }">
                <md-icon>password</md-icon>
                <label>Lösenord</label>
                <md-input v-model="form.password" type="password"></md-input>
                <span class="md-error">{{ formValidators.password }}</span>
              </md-field>
            </md-card-content>

            <div class="text-right forgot-password-container">
              <p>
                <a @click="openForgotPassword">Glömt lösenord?</a>
              </p>
            </div>
            <md-card-actions class="buttons md-layout md-gutter md-alignment-center-center">
              <md-button class="md-primary btn-regular" v-bind:class="{ disabled: !validateLogin(form.email) }" @click="login">
                Logga in
              </md-button>
            </md-card-actions>
          </div>

        </md-card>
      </div>
    </div>
    <modal v-if="showForgotPassword" @close="closeForgotPassword">
      <template slot="header">
        <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="closeForgotPassword"
        >
          <md-icon>clear</md-icon>
        </md-button>
      </template>

      <template slot="body">
        <h3>Glömt lösenord?</h3>
        <br>
        <p>Fyll i din email och så skickar vi en återställnings länk</p>
        <md-field class="md-form-group" :class="loginFormErrorEmail" slot="inputs">
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input v-model="form.email" type="email"></md-input>
          <span class="md-error">{{ form.emailError }}</span>
        </md-field>
        <md-button class="md-primary" @click="resetPassword" v-bind:class="{ disabled: !validateEmail(form.email) }">
          Skicka
          återställning
        </md-button>
      </template>
    </modal>
  </div>
</template>
<script>
import store from "../store";
import sharedConfig from "../../../shared/shared-config";
import Vue from "vue";
import Modal from "../../../shared/components/Modal";
import {validateEmail} from "../../../shared/utils/general-utils";

export default {
  bodyClass: "login",
  components: {
    Modal
  },
  created() {
    document.title = "Inloggning - Organisation";
  },
  data() {
    return {
      showForgotPassword: false,
      form: {
        email: "",
        emailError: false,
        password: "",
        passwordError: false,
        error: "",
        signInError: ""
      },
      formValidators: {}
    };
  },
  methods: {
    checkSignIn() {
      let error = false;
      if (!this.form.email) {
        this.form.error.concat("Email krävs");
        this.form.emailError = "Email krävs";
        error = true;
      } else if (!this.validateEmail(this.form.email)) {
        this.form.error.concat("Inte en giltig email\n");
        this.form.emailError = "Inte en giltig email";
        error = true;
      }
      if (!this.form.password) {
        this.form.error.concat("Lösenords krävs");
        this.form.passwordError = "Lösenord krävs";
        error = true;
      }

      return !error;
    },
    login: function (event) {
      event.preventDefault();
      if (this.checkSignIn()) {
        this.$store.dispatch(sharedConfig.store.actions.AUTH.LOGIN_REQUEST, this.form);
      }
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    validateLogin() {
      return String(this.form.email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) && this.form.password.length > 4;
    },
    closeForgotPassword() {
      this.showForgotPassword = false;
    },
    openForgotPassword() {
      this.showForgotPassword = true;
    },
    resetPassword: function () {
      if (this.form.email && this.form.email.length > 5) {
        this.$store
            .dispatch(sharedConfig.store.verifications.FORGOT_PASSWORD_REQUEST, this.form.email)
            .then(() => {
              this.closeForgotPassword();
              const instance = Vue.$toast.open({
                message: "Återställnings email skickat",
                type: "success",
                position: "top-right"
              });
            }).catch((e) => {
          const instance = Vue.$toast.open({
            message: "Can inte skicka återställnings länk",
            type: "error",
            position: "top-right"
          });
        });
      } else {
        const instance = Vue.$toast.open({
          message: "Ogiltig email",
          type: "info",
          position: "top-right"
        });
      }
    }
  },
  computed: {
    signInError() {
      return store.getters.signInError;
    },
    loginActionError() {
      return store.getters.getLoginDetails.error;
    },
    loginFormError() {
      return {
        'md-invalid': this.form.error.length > 0
      }
    },
    loginFormErrorEmail() {
      return {
        'md-invalid': this.form.emailError.length > 0
      }
    },
    loginFormErrorPassword() {
      return {
        'md-invalid': this.signInError || this.form.passwordError.length > 0
      }
    }
  }
}
</script>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
}
.login-card {
  margin: auto;
}
.login-box {
  max-width: 894px;
  margin: auto;
}
.logo-img {
  max-width: 380px;
  margin: 36px;
}

.md-button {
  width: 165px;
}

.input-container {
  margin: 31px 65px 0 65px;
}

.forgot-password-container {
  margin: 0 85px 31px 0;
}

a {
  cursor: pointer;
}
/*
.md-field {
  height: 56px !important;
  border-radius: 10px;
  border: 0.5px solid #000000;
  border-style: solid;
}*/
</style>