<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main main-raised">
        <div class="section">
          <div class="container">
            <md-card>
              <div class="content">
                <div class="header">
                  <h1>
                    Kontakta support
                  </h1>
                  <p>
                    Vi finns här för att hjälpa till!
                  </p>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-size-100 text-center" v-if="!haveReadDoc">
                    <strong><p>Innan du kontaktar supporten</p></strong>
                    <p>Vi försöker hålla ett aktivt faq dokument där du kan få svar på några av de mest
                      besvarade frågor. Se till att kontrollera det dokumentet först, för att se om det hjälper <a
                          href="/support/documentation">gå till faq</a></p>
                    <p>om du inte hittar svar där kontakta oss!</p>
                    <md-button class="md-primary feedback-button" @click="haveReadyDocumentation">Ja, jag behöver fortfarande hjälp
                    </md-button>
                  </div>
                  <div class="md-layout-item md-size-100 text-center" v-if="haveReadDoc">
                    <md-field :class="{ 'md-invalid': isValid }">
                      <md-icon class="icon-textarea">feedback</md-icon>
                      <label
                          class="label-textarea">{{ !hasEdited ? 'Write a descriptive message of how we can help you' : 'Support message' }}</label>
                      <md-textarea v-model="feedback" type="text"></md-textarea>
                      <span class="md-error">{{ isValid }}</span>
                    </md-field>
                    <md-button class="md-primary feedback-button" :disabled="hasEdited && !!isValid"
                               @click="sendSupportForm">Send
                    </md-button>
                  </div>
                </div>
              </div>
            </md-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sharedConfig from "../../../shared/shared-config";
import BreadCrumbs from "../../../shared/components/Breadcrumb";
import Vue from "vue";

export default {
  bodyClass: "Contact-support",
  components: {
    BreadCrumbs
  },
  created() {
    document.title = "Kontakta support - Organisation";
  },
  data() {
    return {
      hasEdited: false,
      haveReadDoc: false,
    };
  },
  mounted() {
  },
  methods: {
    sendSupportForm() {
      this.$loading(true);
      this.$store.dispatch(sharedConfig.store.other.SEND_FEEDBACK).then(() => {
        this.$loading(false);
        const instance = Vue.$toast.open({
          message: "Thank you! We will get back to you as soon as possible",
          type: "success",
          position: "top-right"
        });
      }).catch((e) => {
        this.$loading(true);
        const instance = Vue.$toast.open({
          message: "Could not send message",
          type: "error",
          position: "top-right"
        });
      });
    },
    haveReadyDocumentation() {
      this.haveReadDoc = true;
    }
  },
  computed: {
    feedback: {
      get() {
        return this.$store.getters.supportMessage;
      },
      set(newValue) {
        this.hasEdited = newValue && newValue.length > 0;
        this.$store.dispatch(sharedConfig.store.other.SET_FEEDBACK, newValue);
      }
    },
    isValid() {
      if (!this.hasEdited) {
        return null;
      } else {
        return this.$store.getters.supportValid;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.md-textarea {
  padding-left: 22px !important;
  min-height: 300px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

.label-textarea {
  margin-left: 33px;
}

.feedback-button {
  margin-top: 44px;
}
.content {
  margin: 30px;
}
</style>
