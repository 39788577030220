<template>
  <div class="ticker-loaded">
      <content-placeholders :rounded="true" class="content-loader-x">
        <content-placeholders-text :lines="5"/>
      </content-placeholders>
  </div>
</template>

<script>


export default {
  name: 'Ticker-loaded',
  components: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

  },
  props: {
  },
}
</script>
<style>
.ticker-loaded .vue-content-placeholders-text__line:nth-child(1) {
  height: 50px !important;
}
.ticker-loaded .vue-content-placeholders-text__line:nth-child(n+2) {
  height: 27px !important;
}
.ticker-loaded .vue-content-placeholders-text__line:nth-child(n+3) {
  height: 14px !important;
}
</style>
