<template>
  <div class="TopStoryspot-loader">
    <content-placeholders :rounded="true" class="TopStoryspot-loader">
      <content-placeholders-text :lines="3"/>
    </content-placeholders>
  </div>
</template>

<script>


export default {
  name: 'TopStoryspot-loader',
  components: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

  },
  props: {
  },
}
</script>
<style>
.TopStoryspot-loader {
  width: 65%;
  min-height: 220px;
}
.TopStoryspot-loader .vue-content-placeholders-text__line:nth-child(1) {
  height: 50px !important;
  width: 30px;
}
.TopStoryspot-loader .vue-content-placeholders-text__line:nth-child(n+2) {
  height: 27px !important;
  width: 85%;
}
.TopStoryspot-loader .vue-content-placeholders-text__line:nth-child(n+3) {
  height: 14px !important;
  width: 80%;
}
</style>
