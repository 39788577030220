<template>
  <div class="TickerBox parent">
    <div class="text-div">
      <TickerLoader v-if="numb == null" class="content-loader-x"/>
      <div v-else>
          <h1>
            <number
              ref="numberCounter"
              :to="numb"
              :delay="0.5"
            />
          </h1>
          <h3>{{ title }}</h3>
          <p>{{ description }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import TickerLoader from '../loaders/Ticker-loaded'

export default {
  name: 'Ticker',
  components: {
    TickerLoader
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  },
  props: {
    numb: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    }
  },
}
</script>
<style scoped>
h1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 74px;
  font-family: 'Inter';
}

h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
}

p {
  font-size: 16px;
  line-height: 24px;
}

.TickerBox {
  margin: 30px !important;
}

.parent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-div {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0 66px 0 66px;
}

@media only screen and (max-width: 959px) {
  h1 {
    font-size: 45px;
  }

  p {
    font-size: 12px;
    line-height: 14px;
  }

  .text-div {
    margin: 0 2% 0 2%;
  }
}

@media only screen and (max-width: 1279px) {
  h1 {
    font-size: 55px;
  }

  p {
    font-size: 14px;
    line-height: 14px;
  }

  .text-div {
    margin: 0 44px 0 44px;
  }
}

.content-loader-x {
  width: 350px;
  align-items: center;
  justify-content: center;
  margin: 60px 66px 0 66px;
}

.TickerBox .text-div .vue-content-placeholders-text__line:nth-child(1) {
  height: 50px !important;
}
.TickerBox .text-div .vue-content-placeholders-text__line:nth-child(n+2) {
  height: 27px !important;
}
.TickerBox .text-div .vue-content-placeholders-text__line:nth-child(n+3) {
  height: 14px !important;
}
</style>
