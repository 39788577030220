import storage from "../../../shared/utils/localstorage-handler";
import sharedConfig from "../../../shared/shared-config";
import storyspotService from "../../../shared/services/storyspot-service"
import {StorySpotPlace} from "../../../shared/models/StoryspotPlace.model";
import {Storyspot} from "../../../shared/models/Storyspot.model";
import {Place} from "../../../shared/models/Place.model";
import { loremIpsum } from "lorem-ipsum";

const state = {
    hasLoadedStoryspots: false,
    isLoadingStoryspots: false,
    storyspotPlaces: [],
}

const getters = {
    getCurrentUserStoryspots(state) {
        return state.storyspotPlaces;
    },
    getStoryspotLoadStatus(state) {
        return {
            isLoading: state.isLoadingStoryspots,
            hasLoaded: state.hasLoadedStoryspots,
        };
    },
    getCurrentUserStoryspotPlaces(state) {
        return state.storyspotPlaces;
    },
}

const actions = {
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST]: ({commit, dispatch}) => {
        commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST);
        return new Promise((resolve, reject) => {
            if (storage.get(sharedConfig.storage.token)) {
                storyspotService.getOrganzationStoryspots().then((storyspotPlaces) => {
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_SUCCESS, storyspotPlaces);
                    resolve();
                }).catch((e) => {
                    console.log(e);
                    commit(sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR, e);
                    reject();
                });
            }
        });
    },
    [sharedConfig.store.actions.STORYSPOT.GET_STORYSPOT_DETAILED_REQUEST]: (
        { commit, dispatch },
        id
    ) => {
        return new Promise((resolve, reject) => {
            storyspotService
                .getStoryspotPlaceById(id)
                .then(sp => {
                    resolve(sp);
                })
                .catch(e => {
                    reject(e);
                });
        });
    }
}

const mutations = {
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_REQUEST]: (state) => {
        state.isLoadingStoryspots = true;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOT_PLACE_REQUEST]: (state) => {
        state.hasLoadedStoryspotsPlaces = false;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_SUCCESS]: (state, storyspotsData) => {
        state.storyspotPlaces = storyspotsData.data
            .map((jsonData) => new StorySpotPlace(new Storyspot(jsonData.storyspot), new Place(jsonData.place)))
            .sort((a, b) => (a.storyspot.createdAt > b.storyspot.createdAt ? -1 : 1))
            .sort((a,b) => (a.storyspot.status.name < b.storyspot.status.name ? -1 : 1))
            .map((a) => {
                a.storyspot.likesCount = Math.floor(Math.random() * (30 - 2 + 1)) + 2;
                a.storyspot._listens = Math.floor(Math.random() * (225 - 25 + 1)) + 25;
                a.storyspot.name = loremIpsum({
                    count: 1,
                    format: "plain",
                });
                a.storyspot.description = loremIpsum({
                    count: Math.floor(Math.random() * (250 - 25 + 1)) + 25,
                    format: "plain",
                })
                a.storyspot.images = [{url: 'https://storage.googleapis.com/story-spot-staging-public/storyspot_default_image_512.png'}]
                a.storyspot.audio = [{id: "random", url: 'https://storage.googleapis.com/story-spot-prod-public/audio/5d75f0a8a7069edd1e9a2901.mp3'}]
                return a;
        })
        state.hasLoadedStoryspots = true;
        state.isLoadingStoryspots = false;
    },
    [sharedConfig.store.actions.STORYSPOT.GET_USER_STORYSPOTS_ERROR]: (state, error) => {
        // TODO. manage error response
        state.isLoadingStoryspots = false;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};