<template>
  <div class="wrapper">
    <div class="main">
      <div class="section">
        <div class="container">
          <div class="text-center" v-if="isLoading">Läser in...</div>
          <div v-if="!isLoading" class="container">
            <SearchBar @send-searchparameter="didChangeSearch" :title="'Våra storyspots'"
                       :storyspotCount="'(' + getStoryspots.length + ')'"/>
            <div v-for="sp in getStoryspots" v-bind:key="sp.storyspot.id">
              <Storyspot :showEdit="false" :storyspotPlace="sp"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from "../../../shared/components/SearchBar";
import Storyspot from "../../../shared/components/cells/StoryspotCell";
import StoryspotMap from "../../../shared/components/StoryspotMap";

export default {
  bodyClass: "My-storyspots",
  components: {
    Storyspot,
    SearchBar,
    StoryspotMap
  },
  created() {
    document.title = "Våra storyspots - Organization";
  },
  data() {
    return {
      searchText: null,
    };
  },
  methods: {
    didChangeSearch(value) {
      this.searchText = value;
    },
  },
  computed: {
    getStoryspots() {
      if (this.searchText) {
        return this.$store.getters.getCurrentUserStoryspots.filter(
            (a) =>
                a.q && a.q.indexOf(this.searchText.toLowerCase()) > -1
        );
      }
      return this.$store.getters.getCurrentUserStoryspots;
    },
    isLoading() {
      return this.$store.getters.isLoadingStoryspots;
    }
  }
};
</script>

<style lang="scss" scoped>

.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin-left: auto;
  margin-right: 0;
}

</style>
