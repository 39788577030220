<template>
  <div class="lineGraphContainer">
        <LinechartLoader v-if="!data"/>
        <LineChartGenerator v-if="data" :chart-id="chartId" :chart-data="data" :chart-options="chartOptions" :styles="styles"/>
  </div>
</template>

<script>
import {Line as LineChartGenerator} from 'vue-chartjs/legacy'
import LinechartLoader from "../loaders/Linechart-loader";
import {
  Chart as ChartJS,
  Tooltip,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
} from 'chart.js'
import {colors} from "../../utils/contants";

ChartJS.register(LineElement, LinearScale, CategoryScale, PointElement, Tooltip)
export default {
  name: 'LineGraph',
  components: {
    LineChartGenerator,
    LinechartLoader
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        transitions: {
          show: {
            animations: {
              x: {
                from: 0
              },
              y: {
                from: 0
              }
            }
          },
          hide: {
            animations: {
              x: {
                to: 0
              },
              y: {
                to: 0
              }
            }
          },
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'linear',
            loop: false
          }
        },
        elements: {
          line: {
            tension: 0.5,
            backgroundColor: '#fff',
            borderColor: colors[2],
          },
          point: {
            radius: 1.5,
            backgroundColor: colors[2],
            borderColor: colors[2],
          }
        },
        scales: {
          y: {
            grid: {
              color: "rgba(50, 50, 50, 0.1)",
            },
            min: 0,
            title: {display: true, text: this.yheadline, padding: 10},
            ticks: {
              beginAtZero: true,
              callback: (value, index, values) => {
                return Number.isInteger(value) ? value : null;
              }
            }
          },
          x: {
            grid: {
              color: "rgba(50, 50, 50, 0.1)",
            },
          }
        },

      },
    }
  },
  computed: {
    styles() {
      return {
        height: '300px !important',
      }
    }
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    data: {},
  },
}
</script>
<style scoped>
.lineGraphContainer {
  margin: 30px;
}

</style>
