<template>
  <div class="main-content">
    <div class="wrapper">
      <div class="main">
        <BreadCrumbs :crumbs="breadcrumbs"/>
        <div class="section">
          <div class="container">
            <div class="text-center disclaimer">
              <h3>Disclaimer</h3>
              <br>
              <p>This website is still running in <strong>beta</strong> an may contain unfinished features or bugs.</p>
              <p>We are continuously working on improving the experience. If you have feedback feel free to contribute
                <a href="/feedback">send feedback</a></p>
            </div>
            <h1>Documentation</h1>
            <br>

            <h2>How accurate is the data?</h2>
            <strong>Answer:</strong>
            <p>The data is based on new implementations of the iOS/Android/web applications made during 2021. And
              started collecting data from the updated app by then. So data collected in 2021 might not be fully
              representative. But 2022 forward should be at least 98% accurate</p>
            <br>

            <h2>How accurate is the statistics?</h2>
            <strong>Answer:</strong>
            <p>The statistics is based on the data. Read about the accuracy above. The statistics presentation is still
              in beta and may contain bugs or errors causing the data to be represented in an incorrect way. We are
              continuously working on improving on this.</p>
            <br>

            <h2>How do I decide what date range to see?</h2>
            <strong>Answer:</strong>
            <p>You set the date range by pressing the <strong>"
              <md-icon>event</md-icon>
              {{ diffDays }} days"</strong> in the top navigation bar, where you can set a start date and and end date.
              When doing this, <strong>ALL</strong> the statistics shown on the page will be from that date range, even
              if the storyspot itself is created outside of that range
            </p>
            <br>

            <h2>What does <strong>Author page</strong> mean?</h2>
            <strong>Answer:</strong>
            <p>The author page references to the page you visit where you can read on the author, and see all the
              storyspots created by an author.</p>
            <br>

            <h2>What does <strong>Storyspot page</strong> mean?</h2>
            <strong>Answer:</strong>
            <p>The storyspot page references to the page you visit where you can read on the storyspot, see all the
              images and the description etc.</p>
            <br>

            <h2>What does <strong>listens</strong> refer to?</h2>
            <strong>Answer:</strong>
            <p>Listens referes to how many people have listed to a storyspot</p>
            <br>

            <h2>Why does the listen count not equal the statistics of listen?</h2>
            <strong>Answer:</strong>
            <p>The listens that is showed in the details of your storyspot is collected over a longer period of this, as
              where the statistics of the listen is collected from mid 2021 going forward ( See answer under "How
              accurate is the data?")</p>
            <br>

            <h2>Why do I have more listens than storyspot visits?</h2>
            <strong>Answer:</strong>
            <p>There are several ways to start listening to a storyspot that does not require a user to visit the
              storyspot page</p>
            <br>

            <h2>Why is there a column named <strong>Unkown</strong> represented in countries/cities?</h2>
            <strong>Answer:</strong>
            <p>We may not always be able to collect the location from statistics, these are then represented as a
              collective Unknown.</p>
            <br>

            <p>
            <h2>Why is there a code <i>(ex: "sv-fr")</i> instead of language represented in the device language chart?
            </h2><i>see <a href="https://www.andiamo.co.uk/resources/iso-language-codes/" target="_blank">ISO 639-1
            standard language codes</a></i></p>
            <strong>Answer:</strong>
            <p>Device languages are always represented by codes, we try to translate these to the representative way of
              the language, but this cannot always be done.</p>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumbs from "../../../../shared/components/Breadcrumb";

export default {
  bodyClass: "documentation",
  components: {
    BreadCrumbs
  },
  data() {
    return {
      breadcrumbs: [
        {
          to: '/',
          name: "Dashboard"
        },
        {
          to: '/support',
          name: "Support"
        },
      ]
    }
  },
  computed: {
    diffDays() {
      return this.$store.getters.diff;
    },
  }
}
</script>
<style lang="scss" scoped>
.disclaimer {
  max-width: 50%;
  margin: auto;
  margin-bottom: 80px;
}
.main {
  min-height: 80vh;
  z-index: 0;
}
</style>