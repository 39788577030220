<template>
  <div class="wrapper">
    <div class="main">
      <div class="section">
        <div class="container">
          <div class="header">
            <h1>
              Våran profil
            </h1>
            <p>Du representerar: <strong>{{ user.fullName }}</strong></p>
          </div>
          <div class="md-layout md-gutter md-card">
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-center mt">
                <ProfilePictureSelector :image="usr.picture" @on-delete="onImageDeleted"
                                        @on-selected="onImageSelected"/>
              </div>
            </div>
            <div class="md-layout details">
              <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                <md-field :class="{ 'md-invalid': validators.fullName }">
                  <md-icon>person</md-icon>
                  <label>Namn (display name)</label>
                  <md-input v-model="usr.fullName" type="text"></md-input>
                  <span class="md-error">{{ validators.fullName }}</span>
                </md-field>
                <md-field>
                  <md-icon>badge</md-icon>
                  <label>Användarnamn</label>
                  <md-input v-model="user.username" type="text" disabled=""></md-input>
                </md-field>
                <md-field>
                  <md-icon class="icon-textarea">description</md-icon>
                  <label class="label-textarea">Beskrivning</label>
                  <md-textarea v-model="usr.description" type="text"></md-textarea>
                </md-field>
              </div>
              <div class="md-layout-item md-size-50 md-small-size-100 md-small-size-100 md-medium-size-50">
                <md-field>
                  <md-icon>email</md-icon>
                  <label>Email</label>
                  <md-input v-model="user.email" type="email" disabled></md-input>
                </md-field>
                <md-switch class="md-primary" v-model="usr.showEmail">
                  {{ 'Min email är '}}
                  <strong>{{ usr.showEmail ? 'publik' : 'osynlig' }}</strong>
                </md-switch>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100">
                <!--  <md-button class="md-primary md-success button-container" @click="updateProfile" :disabled="!canUpdate">
                    {{canUpdate ? 'Update' : 'Nothing to update'}}
                  </md-button> -->
                <md-button class="md-primary md-success button-container" @click="updateProfile" :disabled="true">
                  {{ canUpdate ? 'Uppdatering inaktiverad' : 'Inaktiverad' }}
                </md-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePictureSelector from "../../../shared/components/ProfilePictureSelector"
import sharedConfig from "../../../shared/shared-config"
import Vue from "vue";
import router from "../router";

export default {
  bodyClass: "My-profile",
  components: {
    ProfilePictureSelector
  },
  created() {
    document.title = "Profile - Organization";
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(sharedConfig.store.profile.SET_BASE);
  },
  methods: {
    async updateProfile() {
      let update = await this.$store.dispatch(sharedConfig.store.profile.UPDATABLE_VALUES);
      if (update) {
        this.$loading(true);
        this.$store.dispatch(sharedConfig.store.profile.UPDATE, update).then(() => {
          this.$loading(false);
          const instance = Vue.$toast.open({
            message: "Update successful",
            type: "success",
            position: "top-right"
          });
        }).catch((e) => {
          this.$loading(false);
          const instance = Vue.$toast.open({
            message: "Update failed",
            type: "error",
            position: "top-right"
          });
        })
      } else {
        const instance = Vue.$toast.open({
          message: "Nothing to update",
          type: "success",
          position: "top-right"
        });
      }
    },
    setValue(value, key) {
      this.$store.dispatch(sharedConfig.store.profile.SET, {value, key});
    },
    onImageSelected(img) {
      this.setValue(img, 'profileImage');
    },
    onImageDeleted() {
      this.setValue(null, 'profileImage');
    }
  },
  watch: {
    'usr.fullName': function (val, oldVal) {
      this.setValue(val, 'fullName');
    },
    'usr.description': function (val, oldVal) {
      this.setValue(val, 'description');
    },
    'usr.showEmail': function (val, oldVal) {
      this.setValue(val, 'showEmail');
    },
  },
  computed: {
    isReady() {
      return this.$store.getters.updateProfileViewReady;
    },
    usr() {
      return this.$store.getters.getUpdateProfile;
    },
    validators() {
      return this.$store.getters.profileValidators;
    },
    canUpdate() {
      return this.$store.getters.canUpdateProfile;
    },
    user() {
      return this.$store.getters.getCurrentProfile;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 44px;
}


.image-button-container .md-button {
  width: 100%;
  max-width: 200px;
}

.details {
  margin-top: 45px;
}

textarea {
  display: block;
  padding-left: 0 !important;
  resize: none !important;
}


.section {
  // Used cause we want the create storyspot and search header to act as section "headers"
  padding-top: 20px !important;
}

.main {
  min-height: 80vh;
  z-index: 0;
}

.button-container {
  margin: auto;
  margin-top: 44px;
}

.md-textarea {
  padding-left: 22px !important;
}

.md-field.md-has-textarea:not(.md-autogrow) > .md-icon {
  right: unset;
}

.label-textarea {
  margin-left: 33px;
}

.md-field + .md-has-textarea:not(.md-autogrow) {
  margin-top: 0 !important;
}
.md-card {
  padding: 30px;
  margin-top: 0;
}

.mt {
  margin-top: 25px;
}

</style>
