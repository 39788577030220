import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from './App.vue'
import router from "./router";
import store from "./store"


import MaterialKit from "../src/assets/plugins/material-kit";
Vue.use(MaterialKit);

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAtBkr6nJCMDaztdGfniqYcYTOrYSlYwAQ",
    libraries: "places",
    region: "SE",
    language: "se",
  },
  installComponents: true,
});


import VueAppleLogin from 'vue-apple-login';
Vue.use(VueAppleLogin, {
  clientId: 'se.storyspot.se-app-webb',
  scope: 'name email',
  redirectURI: 'https://app.storyspot.se',
  state: new Date().getTime().toString(),
  usePopup: true,
});

import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import QrcodeVue from 'qrcode.vue'
Vue.use(QrcodeVue);

import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=false',
    'scrollbars=false'
  ],
  styles: [
    '../../shared/style/print_css.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: '', // override the window title
}
Vue.use(VueHtmlToPaper, options);


import VueLoading from "vuejs-loading-plugin";
Vue.use(VueLoading);

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

import VueContentPlaceholders from 'vue-content-placeholders'
Vue.use(VueContentPlaceholders);

const NavbarStore = {
  showNavbar: false
};

Vue.config.productionTip = false
Vue.use(VueMaterial);

Vue.mixin({
  data() {
    return {
      NavbarStore
    };
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
